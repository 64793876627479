<template>
  <div class="log-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="短信记录" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 操作类型 -->
        <ml-input
          prop="titleLike"
          placeholder="请输入短信标题"
          style="margin-bottom: 0"
          label="短信标题:"
          v-model="searchData.titleLike"
        />
        <!-- 日期 -->
        <ml-date-picker
          prop="date"
          startPlaceholder="开始日期"
          endPlaceholder="结束日期"
          label="推送时间:"
          type="datetimerange"
          v-model="searchData.date"
          :style="{ 'margin-bottom': 0, 'margin-right': '40px' }"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="发送消息"
          submitIcon="el-icon-plus"
          :showCancel="false"
          @click-submit="clickSmes"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="消息ID" prop="recordId" min-width="10%" /> -->
        <el-table-column align="center" label="消息标题" prop="title" min-width="10%" />
        <el-table-column
          align="center"
          label="推送阿米巴"
          prop="receiveCompanyName"
          min-width="10%"
        >
          <template #default="scope">
            {{ scope.row.receiveCompanyId === '0' ? '全部' : scope.row.receiveCompanyName }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="发送对象" prop="userStatus" min-width="10%">
          <template #default="scope">
            {{
              scope.row.userStatus === '0'
                ? '全部'
                : scope.row.userStatus === '1'
                ? '指定用户'
                : '其他用户'
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="推送时间" prop="createTime" width="200px" />
        <el-table-column align="center" label="操作" priop="roleId" width="180px">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickView(scope.$index, scope.row)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 发送消息提示框 -->
    <ml-dialog
      width="600px"
      ref="smesDialogRef"
      title="发送消息"
      showClose
      @click-submit="submitSmesDialog"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 100%"
          :model="submitSmesDialogForm"
          :rules="submitSmesDialogFormRules"
          ref="submitSmesDialogFormRef"
        >
          <ml-input
            prop="title"
            label="推送标题:"
            placeholder="请输入推送标题"
            v-model.trim="submitSmesDialogForm.title"
          />
          <ml-select
            prop="templateId"
            placeholder="请选择消息模版"
            :options="smesTemplateData"
            label="消息内容:"
            labelName="templateName"
            valueName="templateId"
            v-model="submitSmesDialogForm.templateId"
            v-model:node="templateNode"
          />

          <ml-input
            prop="content"
            type="textarea"
            :rows="5"
            label=""
            placeholder="请输入消息内容"
            disabled
            v-model.trim="submitSmesDialogForm.content"
          />

          <ml-select
            class="assignedPeople-select"
            prop="receiveCompanyId"
            placeholder="请选择组织单位"
            :options="unitOptions"
            label="组织单位:"
            keyName="companyId"
            labelName="companyName"
            valueName="companyId"
            :disabled="submitSmesDialogForm.userVos.length > 0"
            v-model="submitSmesDialogForm.receiveCompanyId"
            v-model:node="receiveCompanyIdNode"
          />

          <el-form-item label="推送对象" prop="userStatus">
            <el-radio-group v-model="submitSmesDialogForm.userStatus">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">指定人</el-radio>
              <el-button
                v-if="[1].includes(submitSmesDialogForm.userStatus)"
                size="mini"
                type="primary"
                style="margin-right: 20px"
                @click="clickOpenSmesPeopleDialog"
              >
                添加人员
              </el-button>
              <el-radio :label="2">其他人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="
              submitSmesDialogForm.userVos.length > 0 &&
              [1].includes(submitSmesDialogForm.userStatus)
            "
          >
            <el-tag
              style="margin: 5px 10px"
              type="default"
              v-for="item in submitSmesDialogForm.userVos"
              :key="item"
              >{{ item.userName }}</el-tag
            >
          </el-form-item>

          <ml-input
            prop="telephones"
            type="textarea"
            :rows="3"
            label="其他用户"
            :required="submitSmesDialogForm.userStatus === 2"
            placeholder="请输入其它用户手机号（用英文逗号分开）"
            v-model.trim="submitSmesDialogForm.telephones"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 添加指派人提示框 -->
    <ml-dialog
      width="1200px"
      ref="smesPeopleDialogRef"
      title="指定人"
      @clickSubmit="smesPeopleDialogSubmit"
      @clickClose="smesPeopleDialogClose"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              inline
              labelWidth="80px"
              :model="smesPeopleDialogForm"
              ref="smesPeopleDialogFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                label="帐号:"
                v-model="smesPeopleDialogForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                label="用户名:"
                v-model="smesPeopleDialogForm.userNameLike"
              />
              <ml-select
                class="assignedPeople-select"
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                :style="{ marginRight: '40px' }"
                v-model="smesPeopleDialogForm.companyId"
              />
              <!-- <ml-input
                label="阿米巴:"
                disabled
                v-model="smesPeopleDialogForm.companyName"
                style="margin-right: 40px"
              /> -->
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchDataUser"
                @click-submit="searchFnUser"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberDataUser.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAll"
              @select="select"
              :row-key="row => row.userId"
              ref="smesPeopleDialogTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberDataUser.total"
              :page="tabberDataUser.page"
              v-model="tabberDataUser.page"
              :size="tabberDataUser.size"
              @current-change="currentChangeUser"
              @size-change="sizeChangeUser"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
    <!-- 详情 -->
    <ml-dialog
      width="600px"
      ref="smesDetailsDialogRef"
      title="详情"
      :showSubmitBtn="false"
      closeText="关闭"
    >
      <template #body>
        <div class="descriptions-list">
          <div v-for="item in descriptionsList" :key="item">
            <div>{{ item.label }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  formetData,
  mobilePhoneRegExp,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'

export default {
  name: 'SmsSendRecords',
  setup() {
    const { commit, dispatch } = useStore()
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      titleLike: '',
      date: []
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'])
      if (searchData.date.length > 0) {
        searchDataParams.startTime = formetData(searchData.date[0])
        searchDataParams.endTime = formetData(searchData.date[1])
      }
      searchDataParams.titleLike = replacePerCent(searchData.titleLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetSmsSendRecords',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getLogTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    /* 结束 数据列表 **********************************/

    // 发送消息
    // 单位
    const unitOptions = ref([])
    const receiveCompanyIdNode = ref({})
    // 消息模板
    const smesTemplateData = ref([])
    const smesDialogRef = ref()
    // 打开发送
    const clickSmes = () => {
      submitSmesDialogForm.title = ''
      submitSmesDialogForm.templateId = ''
      submitSmesDialogForm.content = ''
      submitSmesDialogForm.telephones = ''
      submitSmesDialogForm.userStatus = 0
      submitSmesDialogForm.userVos = []
      smesDialogRef.value.showDialog = true
    }
    // 确认发送
    const submitSmesDialog = () => {
      submitSmesDialogFormRef.value.CustomFormRef.validate()
        .then(() => {
          const nParams = searchParams(submitSmesDialogForm, ['userVos'], true)
          if (submitSmesDialogForm.userStatus === 1) {
            nParams.userVos = submitSmesDialogForm.userVos
          }
          dispatch('fetchAddSmsSendRecord', nParams).then(async data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
              smesDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }
    // 发送对话框表单
    // 选择指派人列表
    const selectData = ref([])
    const submitSmesDialogFormRef = ref()
    const submitSmesDialogForm = reactive({
      title: '',
      templateId: '',
      content: '',
      receiveCompanyId: '',
      telephones: '',
      userStatus: 0,
      userVos: []
    })
    const templateNode = ref({})
    watch(
      () => templateNode.value,
      newvalue => {
        submitSmesDialogForm.content = newvalue.content
      }
    )
    // 表单校验
    const isUserStatusOne = () => {
      const { userStatus, userVos } = submitSmesDialogForm
      if (userStatus === 1 && userVos.length <= 0) {
        return false
      } else {
        return true
      }
    }
    // 表单校验
    const isUserTelephones = () => {
      const { telephones } = submitSmesDialogForm
      if (!telephones) {
        return true
      } else {
        const telephonesArr = telephones.split(',')
        let isTrue = true
        telephonesArr.map(phone => {
          if (!mobilePhoneRegExp.test(phone)) {
            isTrue = false
          }
        })
        return isTrue
      }
    }
    const submitSmesDialogFormRules = {
      title: [{ required: true, message: '请输入推送标题 ', trigger: 'blur' }],
      templateId: [{ required: true, message: '请输入推送模板 ', trigger: 'blur' }],
      receiveCompanyId: [{ required: true, message: '请选择接收阿米巴 ', trigger: 'blur' }],
      userStatus: [
        { required: true, validator: isUserStatusOne, message: '请选择接收人', trigger: 'blur' }
      ],
      telephones: [
        {
          required: false,
          message: '请输入手机号',
          trigger: ['blur', 'change']
        },
        {
          validator: isUserTelephones,
          message: '请输入正确手机号',
          trigger: ['blur', 'change']
        }
      ]
    }
    watch(
      () => submitSmesDialogForm.userStatus,
      newvalue => {
        if (newvalue === 2) {
          submitSmesDialogFormRules.telephones[0].required = true
        } else {
          submitSmesDialogFormRules.telephones[0].required = false
        }
      }
    )

    // 指定人 *******************************************************************
    // 对话框
    const smesPeopleDialogRef = ref()
    const smesPeopleDialogTableRef = ref()
    // 打开对话框
    const clickOpenSmesPeopleDialog = () => {
      submitSmesDialogFormRef.value.CustomFormRef.validateField(['receiveCompanyId'])
      if (submitSmesDialogForm.receiveCompanyId) {
        if (receiveCompanyIdNode.value.companyName) {
          const { companyId } = receiveCompanyIdNode.value
          smesPeopleDialogForm.companyId = companyId
        }
        selectData.value = submitSmesDialogForm.userVos
        getAssignedPeopleData()
        smesPeopleDialogRef.value.showDialog = true
      }
    }
    // 确定对话框
    const smesPeopleDialogSubmit = () => {
      submitSmesDialogForm.userVos = selectData.value
      smesPeopleDialogRef.value.showDialog = false
      selectData.value = []
      smesPeopleDialogTableRef.value.clearSelection()
    }
    // 关闭对话框
    const smesPeopleDialogClose = () => {
      selectData.value = []
      smesPeopleDialogTableRef.value.clearSelection()
    }

    // 表单
    const smesPeopleDialogFormRef = ref()
    const smesPeopleDialogForm = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: ''
    })

    // 表格配置
    const tabberDataUser = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      isSubmie: false
    })

    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberDataUser.page, pageSize: tabberDataUser.size }
    ) => {
      const searchDataParams = searchParams(smesPeopleDialogForm, ['companyName'])
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberDataUser.data = data || []
      tabberDataUser.total = total || 0
      if (selectData.value.length > 0) {
        selectData.value.map(item => {
          smesPeopleDialogTableRef.value.toggleRowSelection(item, true)
        })
      }
    }

    //分页事件
    watch([() => tabberDataUser.page, () => tabberDataUser.size], () => {
      getAssignedPeopleData()
    })
    const currentChangeUser = page => {
      tabberDataUser.page = page
    }
    const sizeChangeUser = size => {
      tabberDataUser.size = size
    }

    // 重置搜索内容
    const resetSearchDataUser = () => {
      smesPeopleDialogFormRef.value.CustomFormRef.resetFields()
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }
    // 搜索
    const searchFnUser = () => {
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName,
          telephone: item.telephone
        }
      })
    // 选中单个
    const select = selection => {
      selectData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = getSelection(selection)
    }

    // 打开详情
    const smesDetailsDialogRef = ref()
    const smesDetailsDialogData = reactive({
      title: '',
      content: '',
      createTime: '',
      userStatus: 0,
      userVos: []
    })
    const descriptionsList = computed(() => [
      {
        label: '消息标题:',
        value: smesDetailsDialogData.title
      },
      {
        label: '推送对象:',
        value:
          smesDetailsDialogData.userStatus === '0'
            ? '全部'
            : `指定人员（${smesDetailsDialogData.userVos.map(item => item.userName).join()}）`
      },
      {
        label: '推送时间:',
        value: smesDetailsDialogData.createTime
      },
      {
        label: '消息内容:',
        value: smesDetailsDialogData.content
      }
    ])
    const clickView = async (index, row) => {
      const data = await dispatch('fetchGetSmsSendRecord', row.recordId)
      if (data && data.code === 200) {
        Object.keys(smesDetailsDialogData).map(item => {
          smesDetailsDialogData[item] = data.data[item]
        })
      }
      smesDetailsDialogRef.value.showDialog = true
    }

    onMounted(async () => {
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = [{ companyId: '0', companyName: '全部' }, ...data]
      const smesData = await getTabberData(dispatch, 'fetchGetSmsTemplates', {})
      smesTemplateData.value = smesData.data || []

      getLogTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickSmes,
      smesDialogRef,
      submitSmesDialog,
      submitSmesDialogForm,
      submitSmesDialogFormRef,
      submitSmesDialogFormRules,
      unitOptions,
      receiveCompanyIdNode,
      smesTemplateData,
      smesPeopleDialogRef,
      smesPeopleDialogSubmit,
      smesPeopleDialogClose,
      clickOpenSmesPeopleDialog,
      smesPeopleDialogForm,
      smesPeopleDialogFormRef,
      currentChangeUser,
      tabberDataUser,
      sizeChangeUser,
      resetSearchDataUser,
      searchFnUser,
      select,
      selectAll,
      smesPeopleDialogTableRef,
      templateNode,
      smesDetailsDialogRef,
      clickView,
      descriptionsList
    }
  }
}
</script>

<style lang="scss">
.log-manage {
  @extend %params-global;
}
.descriptions-list {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    min-height: 35px;
    margin-bottom: 20px;
    > div:last-child {
      flex: 1;
      margin-left: 20px;
    }
  }
}
</style>
